import invariant from 'invariant';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import warning from 'warning';

export const dedupedBy = (array: any[], mapper: any) => uniqBy(array, mapper);
export const deduped = (array: any[]) => dedupedBy(array, undefined);
export const reversed = (array: any[]) => array.slice().reverse();

export const computeEntries = <T>(list: T[], keyField: undefined | string | ((value: T) => any), dedupe: boolean): Array<{
  key: string,
  value: T;
}> => {
  if (!Array.isArray(list)) {
    warning(
      false,
      `computeEntries: list must be an array, but got type "${
        typeof list
      }": ${
        JSON.stringify(list, null, 2)
      }`,
    );
    return [];
  }
  const keyCount = Object.create(null);
  return list.reduce<Array<{key: string, value: any}>>((output, value) => {
    let rawKey;
    if (!keyField) {
      rawKey = value;
    } else if (typeof keyField === 'string') {
      rawKey = get(value, keyField);
    } else if (typeof keyField === 'function') {
      rawKey = keyField(value);
    } else {
      invariant(
        false,
        `computeEntries: keyField must be a string, func, or falsy value, but got type "${
          typeof keyField
        }": ${
          JSON.stringify(keyField, null, 2)
        }`,
      );
    }
    if (typeof rawKey !== 'string' || !rawKey) {
      warning(
        false,
        'computeEntries: keyField values must be non-empty strings, or list items must be ' +
          'strings if keyField is not defined',
      );
      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable:no-console
        console.warn('list:', list);
        console.warn('keyField:', keyField);
        console.warn(`rawKey (${typeof rawKey}):`, rawKey);
        // tslint:enable:no-console
      }
      // Avoid skipping items that don't have keys. This is quite rare, but can happen if an admin
      // saves incomplete data.
      rawKey = JSON.stringify(value); // TODO: This should be optimized
    }

    keyCount[rawKey] = (keyCount[rawKey] || 0) + 1;
    const count = keyCount[rawKey];

    if (dedupe && count > 1) {
      return output;
    }

    const key = `${rawKey}__[${count}]`;
    output.push({ key, value });
    return output;
  }, []);
};

export function moveElementToEndByIndex<T>(list: T[], index) {
  const newList = [...list];
  if (index > 0) {
    newList.push(...newList.splice(index, 1));
  }
  return newList;
}
