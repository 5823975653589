import { ApisProvider } from 'contexts/ApisContext';
import { FloatingCartButtonProvider } from 'contexts/FloatingCartButtonContext';
import { FullScreenDetectorProvider } from 'contexts/FullScreenDetectorContext';
import { PanelsProvider } from 'contexts/PanelsContext';
import { VideoLibraryProvider } from 'contexts/VideoLibraryContext';
import { GlobalVideoContextProvider } from 'contexts/GlobalVideoContext';
import React, { PropsWithChildren } from 'react';
import { Provider as ReactReduxProvider } from 'react-redux';
import { store } from 'services';
import { FontsProvider } from 'contexts/FontsContext';

const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ReactReduxProvider store={store()}>
      <ApisProvider>
        <FontsProvider>
          <GlobalVideoContextProvider>
            <FullScreenDetectorProvider>
              <FloatingCartButtonProvider>
                <VideoLibraryProvider>
                  <PanelsProvider>{children}</PanelsProvider>
                </VideoLibraryProvider>
              </FloatingCartButtonProvider>
            </FullScreenDetectorProvider>
          </GlobalVideoContextProvider>
        </FontsProvider>
      </ApisProvider>
    </ReactReduxProvider>
  );
};

export default Providers;
